import React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ShortCodes } from './shortcode';
import Alert from '../../shared/components/Alert';
// import { Questions } from './shortcode';

class CreateShortCodes extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            event_name: '',
            event_description: '',
            start_time: '',
            end_time: '',
            entity_type: '4',
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',
            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }],

            priority: [{
                name: '1'
            }, {
                name: '2'
            },
            {
                name: '3'
            },
            {
                name: '4'
            },
            {
                name: '5'
            },
            {
                name: '6'
            },
            {
                name: '7'
            },
            {
                name: '8'
            },
            {
                name: '9'
            },
            {
                name: '10'
            }],
            avenue: '',
            assigned_to: '',
            selectedCounty: 'Select County/State Name',
        }
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    onSubmit(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 1000)

        let formData = {
            "shortcode": this.state.shortcode,
            "name": this.state.name
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + 'shortcodes', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });

                    setTimeout(() => {
                        this._onButtonClick()
                        this.setState({ statusMessage: "", name: "", shortcode: "", isShowError: false, isLoading: false });
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };

    handleChangeEntityChange = (event) => {
        this.setState({ values: event.target.value });
    };

    handleChangeEntityType = (event) => {
        this.setState({ entity_type: event.target.value });
    };
    handleChangeApplicable = (event) => {
        this.setState({
            applicable_to: event.target.value,
        });
        if (event.target.value === 'entity') {

            this.setState({
                hideEntitiy: true,
                hideBranch: false,
            });
        } else if (event.target.value === 'branch') {
            this.setState({
                hideBranch: true,
                hideEntitiy: false,
            });

        }
        else if (event.target.value === 'class') {
            this.setState({
                hideBranch: false,
                hideEntitiy: false,
            });

        }
        else if (event.target.value === 'stream') {
            this.setState({
                hideBranch: false,
                hideEntitiy: false,
            });

        }
    };
    handleChangeTransit = (event) => {
        this.setState({ transit: event.target.value });
    };
    handleChangeFee = (event) => {
        this.setState({ fee: event.target.value });
    };
    handleChangePlans = (event) => {
        this.setState({ plans: event.target.value });
    };
    handleChangeScope = (event) => {
        this.setState({ event_scope: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCountry = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    handleChangeCounty = (event) => {
        this.setState({ selectedCounty: event.target.value });
    };

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;


        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <ShortCodes /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Create ShortCode</h4>
                                </div>
                                <div className="col-md-6">
                                    <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                        ShortCodes
                                    </Button>

                                </div>
                            </div>

                            {this.state.showError ? <div >

                                <Alert color="danger" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>


                            </div> : null}<br></br>

                            {this.state.isShowError ? (
                                <div>

                                    <Alert color="success" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>

                                </div>

                            ) : null}<br></br>
                            <form className="form" onSubmit={this.onSubmit}>


                                <div className="col-md-10  offset-1">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">ShortCode</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="text" className="form-control input-md"
                                                    name="shortcode" required placeholder="Enter Shortcode"
                                                    value={this.state.shortcode} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-10 offset-1">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Name</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="text" className="form-control" name="name"
                                                    required placeholder="Enter Name"
                                                    value={this.state.name}
                                                    onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-10 offset-1">                                    <br />
                                    <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                        {this.state.isLoading ? "Please Wait..." : "Submit"}

                                    </Button> &nbsp;&nbsp;&nbsp;
                                        </div>
                            </form>
                        </CardBody>
                    </Card>

                )}
            </div>
        )
    }
}
export default withTranslation('common')(CreateShortCodes);
