import React, { useState, useEffect } from 'react'
import { Bar } from '@reactchartjs/react-chart.js'
import Panel from '../../shared/components/Panel';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import axios from 'axios';
import Select from 'react-select';
import { Link } from '@material-ui/core';
import { Redirect, Router } from 'react-router';
import { Button } from 'reactstrap';

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
}

function MessageTermination() {

    const [records, setRecords] = useState();
    const [shortcode, setShortcode] = useState("20538")
    const [success, setSuccess] = useState([]);
    const [failure, setFailure] = useState([]);
    const [shortcodename, setShortcodename] = useState([]);
    const [label, setLabel] = useState([]);

    useEffect(() => {

        axios.all([
            axios.get(baseURL + `dlrstats?shortcode=${shortcode}`, { headers: { "Authorization": `Bearer ${TOKEN}` } }),
            axios.get(baseURL + `shortcodes?page_size=1000`, { headers: { "Authorization": `Bearer ${TOKEN}` } })
        ]).then(axios.spread((shortcodeResponse, balanceResponse) => {
            let successArray = [];
            let failArray = [];
            let labelArray = [];
            shortcodeResponse.data.map((d) => {
                successArray.push(d.success);
                failArray.push(d.failed);
                labelArray.push(d.date);
            })
            setShortcodename(balanceResponse.data.data)
            console.log(balanceResponse.data);
            setSuccess(successArray);
            setFailure(failArray);
            setLabel(labelArray);


        })).catch(e => {
            console.log("jnerjbnjrbjebrbejbj", e);
        })
    }, [shortcode]);


    return (
        <>
            <Panel xs={12} md={12} lg={12} xl={12}>
                <div className='header'>
                    <div className="row">
                        <div className="col-5">
                            <h4 className='title'>SMS Delivery Statistics</h4>
                        </div>
                        <div className="col-4 pull-right">
                            <div className="col-8 offset-2 form-group">
                                <Select
                                    value={{ label: shortcode, value: shortcode }}
                                    onChange={e => { setShortcode(e.value.toString()) }}
                                    options={(shortcodename && shortcodename.length > 0 &&
                                        shortcodename.map((countyItem, i) =>
                                            ({ label: countyItem.shortcode, value: countyItem.shortcode })))}
                                    placeholder="---Select Shortcode---"
                                    className='drop'
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="col-md-12">
                                <Button className="pull-right btn-sm" onClick={event => window.location.href = '/delivery-details'}
                                    color="primary" outline>View More</Button><br /> <br /><br />
                            </div>

                        </div>
                    </div><br />
                </div>
                <Bar data={{
                    labels: label,
                    datasets: [
                        {
                            label: 'No. of Failed SMS',
                            data: failure,
                            backgroundColor: 'rgb(255, 99, 132)',
                        },
                        {
                            label: 'No. of SUCCESS SMS',
                            data: success,
                            backgroundColor: 'rgb(54, 162, 235)',
                        }
                    ],
                }} options={options} width={90}
                    height={25} />
            </Panel>
        </>
    )
}

export default MessageTermination