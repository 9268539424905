import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col, Form } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { Subscribers } from './subscribers';

export class SubscriberHistory extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this.columns = [
            // {
            //     key: "id",
            //     TrOnlyClassName: 'tsc',
            //     text: "#",
            //     className: "tsc",
            //     align: "left"
            // },

            {
                key: "offer_code",
                TrOnlyClassName: 'tsc',
                text: "Offer Code",
                className: "tsc",
                align: "left"
            },
            {
                key: "transaction_id",
                TrOnlyClassName: 'tsc',
                text: "Transaction ID",
                className: "tsc",
                align: "left"
            },
            // {
            //     key: "clientID",
            //     TrOnlyClassName: 'tsc',
            //     text: "Client ID",
            //     className: "tsc",
            //     align: "left"
            // },
            // {
            //     key: "subscriber_lifecycle",
            //     TrOnlyClassName: 'tsc',
            //     text: "Subscription LifeCyle",
            //     className: "tsc",
            //     align: "left"
            // },

            // {
            //     key: "subscription_status",
            //     TrOnlyClassName: 'tsc',
            //     text: "Subscription Status",
            //     className: "tsc",
            //     align: "left"
            // },

            // {
            //     key: "channel",
            //     TrOnlyClassName: 'tsc',
            //     text: "Channel",
            //     className: "tsc",
            //     align: "left"
            // },
            // {
            //     key: "type",
            //     TrOnlyClassName: 'tsc',
            //     text: "Type",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "offer_name",
                TrOnlyClassName: 'tsc',
                text: "Offer Name",
                className: "tsc",
                align: "left"
            },
            {
                key: "phone",
                TrOnlyClassName: 'tsc',
                text: "ShortCode",
                className: "tsc",
                align: "left"
            },


            {
                key: "mail",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center" style={{ fontSize: "1em", color: "white" }}>
                            {record.mail === 'Active' ?
                                <span class="badge-success" style={{ borderRadius: "5px", }}>
                                    {record.mail}
                                </ span>
                                : null}

                            {record.mail === 'Inactive' ?
                                <span class="badge-danger" style={{ borderRadius: "5px", }}>
                                    {record.mail}
                                </ span>
                                : null}
                        </Fragment >
                    );
                }
            },
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date Created",
                className: "tsc",
                align: "left"
            },
            // {
            //     key: "action",
            //     text: "Options",
            //     TrOnlyClassName: 'cell',
            //     className: "cell",
            //     width: 250,
            //     sortable: false,
            //     cell: record => {
            //         return (
            //             <Fragment className="center" >

            //                 <button className=" btn-success"
            //                     title="Reject"
            //                     style={
            //                         { marginRight: '10px' }}
            //                     onClick={
            //                         () => { this.onAnswers(record) }} >
            //                     <span className="fa fa-eye dt-icon-btn" > More </span>
            //                 </button>



            //             </Fragment>
            //         );
            //     }

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            showModals: false,
            showEdit: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        let url = baseURL + "subscribersHistory/" + localStorage.getItem("msidn_id") + "?" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)

                    if (this.state.admins.length > 0 || this.state.admins.length === 0) {

                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: branchResponse.data.total
                            })
                            let mail;
                            let mbalance;
                            let phone, clientID, UserData;
                            let index = { idx: i + 1 }
                            if (this.state.admins[i].subscription_status == "1") {
                                mail = { mail: "Active" };
                            } else {
                                mail = { mail: "Inactive" };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }

                            if (this.state.admins[i].short_code === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].short_code };
                            }


                            if (this.state.admins[i].client_transactionid === null) {
                                clientID = { clientID: "_" };
                            } else {
                                clientID = { clientID: this.state.admins[i].client_transactionid };
                            }

                            if (this.state.admins[i].user_data === null) {
                                UserData = { UserData: "_" };
                            } else {
                                UserData = { UserData: this.state.admins[i].user_data };
                            }

                            let date = { dates: moment.utc(this.state.admins[i].date_created).format('yyyy-MM-DD HH:mm:ss') };
                            data.push(Object.assign(date, mail, phone, clientID, UserData, index, mbalance, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);

                        }
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
        });
    }



    onAnswers(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
        }, function () {
            localStorage.setItem("question_id", this.state.survey_id)
        });

    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal } = this.state;
        const { showModals } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (

                    < Card >
                        <CardBody >
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>List of subscription Histories</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick} outline>Subscribers
                                                </Button>
                                    </div>
                                </div>
                                <br />
                                {this.state.isShowError ?
                                    <div className="alert alert-success" > {this.state.statusMessage}
                                    </div> : null
                                }
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    total_record={this.state.total}
                                    onChange={this.tableChangeHandler} />
                            </div>
                        </CardBody>

                    </Card>

                )}
                {showComponent && (
                    < >
                        <Subscribers />
                    </>
                )}

                {/* {showModal && (
                    < >
                        <Sub />
                    </>
                )} */}

                {/* {showModals && (
                    < >
                        <QuestionCreate />
                    </>
                )} */}

            </div>
        )
    }
}