import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col, Form } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { ROLE, TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import createBN from './createBN';
import EditBreakingNews from './editbroad';
import EditBreakingNews2 from './editBreak';

import { confirm } from "../../shared/components/confirm";

import * as $ from 'jquery';
import jQuery from 'jquery';
import EditableTable from '../../shared/components/table/EditableTable';
import Alert from '../../shared/components/Alert';
window.$ = window.jQuery = require('jquery')

export class BreakingNews extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);



        this.columns = [
            // {
            //     key: "idx",
            //     TrOnlyClassName: 'tsc',
            //     text: "#",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "message",
                TrOnlyClassName: 'tsc',
                text: "Message",
                className: "tsc",
                align: "left"
            },

            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.status === 'APPROVED' ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px", fontSize: "15px" }}>
                                    {record.status}
                                </span>
                                : null}

                            {record.status === 'REJECTED' ?
                                <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px", fontSize: "15px" }}>
                                    {record.status}
                                </span>
                                : null}
                            {record.status === 'PENDING' ?

                                <span span class="badge-info" style={{ borderRadius: "5px", padding: "2px", fontSize: "15px" }}>
                                    {record.status}
                                </span>
                                : null}

                        </Fragment >
                    );
                }
            },

            {
                key: "termination_rate",
                TrOnlyClassName: 'cell',
                text: "Termination Rate",
                className: "cell",
                align: "left"
            },

            {
                key: "dates",
                TrOnlyClassName: 'cell',
                text: "Date Created",
                className: "cell",
                align: "left"
            },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <>{
                            ROLE === '2' || ROLE === "1" ?
                                record.status === 'PENDING' ?
                                    <Fragment className="center" >
                                        <button className="btn btn-success btn-sm"

                                            onClick={
                                                () => { this.handleOnClick(record) }} >
                                            Approve
                                </button>
                                        <button className="btn btn-primary btn-sm"
                                            title="Edit"

                                            onClick={
                                                () => { this.onEditBreaking(record) }} >
                                            Edit
                                    </button>
                                        <button className="btn btn-danger btn-sm"
                                            title="Reject"

                                            onClick={
                                                () => { this.onAnswers(record) }} >
                                            Reject
                                    </button>
                                    </Fragment>
                                    : null
                                : null
                        }

                            {
                                ROLE === '4' ?
                                    record.status === 'PENDING' ?
                                        <Fragment className="center" >

                                            <button className="btn btn-primary btn-sm"
                                                title="Edit"

                                                onClick={
                                                    () => { this.onEditBreaking(record) }} >
                                                Edit
                                    </button>

                                        </Fragment>
                                        : null
                                    : null
                            }
                        </>
                    );
                }
            }

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            showModals: false,
            showEdit: false,
            hideComponent: false,
            data: [],
        };
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onApprove(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    onApprove(record) {

        let formData = {
            "status": "APPROVED",
            "reject_reason": "NULL",

            "message": record.message
        }

        console.log("data", formData)
        axios.put(baseURL + "breakingNews/" + record.id, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)

            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false, showError: false })
                }, 2000)
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000)
        });
    }


    onSubmit(e) {
        e.preventDefault();
        this.setState({ isLoading: true });

        let formData = {
            "status": "REJECTED",
            "reject_reason": this.state.reason
        }

        console.log("data", formData)
        axios.put(baseURL + "breakingNews/" + this.state.recordID, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)

            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        // this._onButtonClick3();
                        this.getData();
                        this._onButtonClick3();

                        this.setState({ reason: "", isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ showError: false })
                }, 2000)
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000)
        });
    }



    onDelete(record) {
        let formData = {
            "mobile": record.mobile
        }
        console.log("Delete Record", record);
        axios.delete(baseURL + "breakingNews/" + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                console.log("records", formData)
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                    }, 2000);

                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false, statusMessage: "" })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }


    componentDidMount() {
        // $('#message').countSms('#sms-counter');

        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        let url = baseURL + "breakingNews?" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)

                    if (this.state.admins.length > 0 || this.state.admins.length === 0) {

                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: branchResponse.data.total
                            })
                            let mail;
                            let mbalance;
                            let phone;
                            let index = { idx: i + 1 }
                            if (this.state.admins[i].email == null) {
                                mail = { mail: "_" };
                            } else {
                                mail = { mail: this.state.admins[i].email };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }
                            let termination_rate
                            if (this.state.admins[i].schedules === null) {
                                termination_rate = { termination_rate: '_' };
                            } else {
                                termination_rate = { termination_rate: this.state.admins[i].schedules.dlr_rate+ "/" +this.state.admins[i].schedules.count_sent };

                            }

                            
                            let date = { dates: moment.utc(this.state.admins[i].created_at).format('YYYY-MM-DD HH:mm:ss') };
                            data.push(Object.assign(date, mail, phone, termination_rate, index, mbalance, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);


                        }
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
        });
    }

    _onButtonClick2() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
            showModals: false
        });
    }

    onEdit(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showEditBreaking: false,
            showEdit: true,
        }, function () {
            // localStorage.setItem("question_id", this.state.survey_id)
        });

    }

    onAnswers(record) {
        localStorage.setItem("broadcastid", record.id)
        localStorage.setItem("broadmessage", record.message)

        this.setState({
            showComponent: false,
            hideComponent: true,
            breakName: record.message,
            showEditBreaking: false,
            recordID: record.id,
            showModal: true,
        }, function () {
        });

    }



    onEditBreaking(record) {
        localStorage.setItem("broadcastid", record.id)
        localStorage.setItem("broadmessage", record.message)
        localStorage.setItem("shortcode_id_edit", record.shortcode_id)
        localStorage.setItem("service_id_edit", record.service_id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            breakName: record.message,
            showEditBreaking: true,
            recordID: record.id,
            showModal: false,
        }, function () {
        });

    }


    _onButtonClick3() {
        this.getData();
        this.setState({
            showComponent: false,
            hideComponent: false,
            showModal: false,
            _onShow: false,
            showEdit: false,
            showEditBreaking: false,
            editShow: false,
            showModals: false
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeMessageResaons = (event) => {
        this.setState({ reason: event.target.value }, function () {

        });
        //alert(event.target.value)
    };


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal, showEditBreaking } = this.state;
        const { showModals } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (

                    < Card >
                        <CardBody >
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>List of Breaking News</b></h4>
                                    </div>
                                    {ROLE === '4' || ROLE === '2' ?

                                        <div className="col-md-6">
                                            <Link to="createBreakingNews">
                                                <Button className="pull-right btn-sm" color="primary
                                                    " onClick={this._onButtonClick2} outline>Create Breakng News <i className="fa fa-plus"></i>
                                                </Button>
                                            </Link>
                                        </div>
                                        : null}
                                </div>
                                {this.state.showError ? <div>
                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>
                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>
                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>
                                    </div>
                                ) : null}
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    total_record={this.state.total}
                                    onChange={this.tableChangeHandler} />
                            </div>
                        </CardBody>

                    </Card>

                )}
                {showComponent && (
                    < >
                        <createBN />
                    </>
                )}

                {showEditBreaking && (
                    < >
                        <EditBreakingNews2 />
                    </>
                )}
                {showModal && (
                    <>
                        <EditBreakingNews />
                        {/* <Col md={12}>
                            <h3>Broadcast</h3><br />
                        </Col>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5 className="bold-text">Reject this message :  {this.state.breakName}  </h5>
                                        </div>


                                        {this.state.showError ? <div style={{ color: 'red' }}>
                                            {this.state.statusMessage}

                                        </div> : null}<br></br>

                                        {this.state.isShowError ? (
                                            <div
                                                color="success"
                                                style={{ fontSize: "13px", color: "green" }}>
                                                {this.state.statusMessage}
                                            </div>

                                        ) : null}<br></br>
                                        <form className="col-md-8 offset-md-2" onSubmit={this.onSubmit}>

                                            <br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <textarea name="message" required style={{
                                                            paddingTop: '20px',
                                                            borderRadius: "5px", borderColor: "hsl(0,0%,80%)"
                                                        }}
                                                            onChange={this.handleChangeMessageResaon} className="col-md-12"
                                                            placeholder="Hint: Enter Rejection Message Here"
                                                            id="message" cols="col-md-1" rows="20"></textarea>
                                                    </div>
                                                   
                                                </div>
                                                <br />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <br />
                                                <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right
                                                                 " >
                                                    {this.state.isLoading ? "Please Wait..." : "Reject"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;
                                                 </div>
                                        </form>
                                    </div>


                                </CardBody>
                            </Card>
                        </Col> */}
                    </>
                )
                }



                {/* {showModals && (
                    < >
                        <QuestionCreate />
                    </>
                )} */}

            </div >
        )
    }
}