import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col, Form } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { Services } from '../Services/services';
import CreateShortCode from './CreateShortCode';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';

export class ShortCodes extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this.onSubmitUpdates = this.onSubmitUpdates.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [
            // {
            //     key: "id",
            //     TrOnlyClassName: 'tsc',
            //     text: "#",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "shortcode",
                TrOnlyClassName: 'tsc',
                text: "ShortCode",
                className: "tsc",
                align: "left"
            },
            {
                key: "name",
                TrOnlyClassName: 'tsc',
                text: "Name",
                className: "tsc",
                align: "left"
            },

            {
                key: "mail",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center" style={{ fontSize: "1em", color: "white" }}>
                            {record.mail === 'Active' ?
                                <span class="badge-success" style={{ borderRadius: "5px", }}>
                                    {record.mail}
                                </ span>
                                : null}

                            {record.mail === 'Inactive' ?
                                <span class="badge-danger" style={{ borderRadius: "5px", }}>
                                    {record.mail}
                                </ span>
                                : null}
                        </Fragment >
                    );
                }
            },
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date Created",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-primary btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onEdit(record) }} >
                                Edit
                            </button>
                            <button className="btn btn-danger btn-sm"
                                title="Delete details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(record) }} >
                                Remove
                             </button>
                            <button className="btn btn-success btn-sm"
                                title="Questions details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onServices(record) }} >
                                Services
                       </button>



                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            showModals: false,
            showEdit: false,
            hideComponent: false,
            data: [],
        };
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        let url = baseURL + "shortcodes?" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            console.log('bayoo', branchResponse)

            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)
                    // if (branchResponse.data.status) {

                    for (let i = 0; i < this.state.admins.length || 0; i++) {
                        this.setState({
                            total: branchResponse.data.total
                        })
                        let mail;
                        let mbalance;
                        let phone;
                        let index = { idx: i + 1 }
                        if (this.state.admins[i].status == "1") {
                            mail = { mail: "Active" };
                        } else {
                            mail = { mail: "Inactive" };
                        }


                        mbalance = { mbalance: this.state.admins[i].balance };


                        if (this.state.admins[i].msisdn === null) {
                            phone = { phone: "_" };
                        } else {
                            phone = { phone: this.state.admins[i].msisdn };
                        }

                        
                        let date = { dates: moment.utc(this.state.admins[i].date_created).format('YYYY-MM-DD HH:mm:ss') };


                        data.push(Object.assign(date, mail, phone, index, mbalance, this.state.admins[i]));
                        this.setState({
                            data: data
                        })
                        console.log("bugs", data);

                    }
                    // } else {
                    //     alert(branchResponse.data.status_message)


                    // }


                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    onSubmitUpdates(e) {
        e.preventDefault();

        let formData = {
            "shortcode": this.state.shortcode,
            "name": this.state.name
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.put(baseURL + 'shortcodes/' + this.state.shortcode_id, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {

                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    setTimeout(() => {
                        this.setState({
                            showComponent: false,
                            hideComponent: false,
                            statusMessage: "", showError: false, isShowError: false,
                            showEdit: false,
                            showModal: false,
                        });
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
                    setTimeout(() => {
                        this.setState({
                            statusMessage: "", showError: false, isShowError: false,
                        });
                    }, 2000);

                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    _onButtonClick() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showModals: false,
            showEdit: false,
            showModal: false,
        });
    }

    _onButtonClick2() {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: false,
            showEdit: false,
            showModals: true
        });
    }


    onDelete(record) {

        console.log("Delete Record", record);
        axios.delete(baseURL + "shortcodes/" + record.shortcode_id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false, showError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }

    onEdit(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showEdit: true,
            name: record.name,

            shortcode_id: record.shortcode_id,

            shortcode: record.shortcode
        }, function () {
            //  localStorage.setItem("", this.state.survey_id)
        });

    }

    onServices(record) {
        window.localStorage.setItem("shortcode_id", record.shortcode_id)
        window.localStorage.setItem("shortcode_name", record.name)
        window.localStorage.setItem("shortcode_codename", record.shortcode)

        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
        });

    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal } = this.state;
        const { showModals } = this.state;
        const { showEdit } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    < Card >
                        <CardBody >
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>List of ShortCodes</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="createshortcodes">
                                            <Button className="pull-right btn-sm" color="primary
                                                    " onClick={this._onButtonClick2} outline>Create ShortCode <i className="fa fa-plus"></i>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>

                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    total_record={this.state.total}
                                    onChange={this.tableChangeHandler} />
                            </div>
                        </CardBody>

                    </Card>

                )}
                {showComponent && (
                    < >
                        <Services />
                    </>
                )}
                {/* {showModal && (
                    < >
                        <Answers />
                    </>
                )} */}


                {showModals && (
                    < >
                        <CreateShortCode />
                    </>
                )}
                {showEdit && (

                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4><b>Edit ShortCodes</b></h4>
                                </div>
                                <div className="col-md-6">
                                    <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                        ShortCodes
                                           </Button>

                                </div>
                            </div>

                            {this.state.showError ? <div >

                                <Alert color="danger" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>


                            </div> : null}<br></br>

                            {this.state.isShowError ? (
                                <div>

                                    <Alert color="success" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>

                                </div>

                            ) : null}<br></br>

                            <form className="form" onSubmit={this.onSubmitUpdates}>
                                <div className="col-md-10 offset-1">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">ShortCode</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="text" className="form-control input-md"
                                                    name="shortcode" required placeholder="Enter Shortcode"
                                                    value={this.state.shortcode} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-10 offset-1">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Name</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="text" className="form-control" name="name"
                                                    required placeholder="Enter Name"
                                                    value={this.state.name}
                                                    onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-10 offset-1">                                            <br />
                                    <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                        {this.state.isLoading ? "Please Wait..." : "Submit"}

                                    </Button> &nbsp;&nbsp;&nbsp;
                                        </div>
                            </form>

                        </CardBody>
                    </Card>

                )
                }
            </div>
        )
    }
}