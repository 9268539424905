import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col, Form } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Survey } from '../Survey/survey';
import { Answers } from '../Answers/answers';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import roles from './roles';
import { Roles } from './roles';
import Alert from '../../shared/components/Alert';


export class Grps extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);

        this.columns = [
            // {
            //     key: "id",
            //     TrOnlyClassName: 'tsc',
            //     text: "#",
            //     className: "tsc",
            //     align: "left"
            // },

            {
                key: "name",
                TrOnlyClassName: 'tsc',
                text: "Name",
                className: "tsc",
                align: "left"
            },
            {
                key: "mail",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center" style={{ fontSize: "1em", color: "white" }}>
                            {record.mail === 'Active' ?
                                < span class="badge-success" style={{ fontSize: "14px", borderRadius: "5px", padding: "2px", color: "white" }} >
                                    {record.mail}
                                </ span>
                                : null}

                            {record.mail === 'Inactive' ?
                                < span class="badge-danger" style={{ fontSize: "14px", borderRadius: "5px", padding: "2px", color: "white" }}>
                                    {record.mail}
                                </ span>
                                : null}
                        </Fragment >
                    );
                }
            },
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date Created",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button class="btn btn-primary btn-sm mb1 bg-blue"
                                title="Eith details"

                                onClick={
                                    () => { this.onView(record) }} >
                                More
                            </button>


                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            showModals: false,
            showEdit: false,
            hideComponent: false,
            data: []
        };
    }

    componentDidMount() {
        this.getData();
    }
    // localStorage.setItem("survey_id", record.survey_id)
    // localStorage.setItem("survey_name", record.name)

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        let url = baseURL + "groups?" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            console.log("BUG", branchResponse)

            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)


                    if (branchResponse.status) {

                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: branchResponse.data.total
                            })
                            let mail;
                            let mbalance;
                            let phone;
                            // let index = { idx: i + 1 }
                            if (this.state.admins[i].status == "1") {
                                mail = { mail: "Active" };
                            } else {
                                mail = { mail: "Inactive" };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            let date = { dates: moment.utc(this.state.admins[i].created_at).format('YYYY-MM-DD') };
                            data.push(Object.assign(date, mail, phone, mbalance, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);

                        }
                    } else {
                        alert(branchResponse.data.status_message)
                        this.setState({
                            showComponent: true,
                            hideComponent: true,
                            showModal: false,
                        });

                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showEdit: false,
            showModal: false,
        });
    }

    _onButtonClick2() {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: false,
            showEdit: false,
            showModals: true
        });
    }

    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showModal: false,
            showEdit: false,
            showModals: false
        });
    }

    onView(record) {
        localStorage.setItem("admin_name", record.name)
        localStorage.setItem("groupadmin_id", record.id)
        this.setState({
            showComponent: true,
            hideComponent: true,
        }, function () {
        });

    }


    ReactTypes() {
        return (this.state.priority && (this.state.priority.length > 0 || this.state.priority.length === 0) &&
            this.state.priority.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.name })))
    }
    handleChangeType = value => {
        this.setState({ selectedType: value.value.toString() });
    };

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal } = this.state;
        const { showModals } = this.state;
        const { showEdit } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
             
                            < Card >
                                <CardBody >

                                    <div className="panel-body" >
                                        <h4><b>Groups</b></h4>

                                        <div className="row">
                                            <div className="col-md-6">
                                            </div>
                                            {/* <div className="col-md-6">
                                                <Link to="questionCreate">
                                                    <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick2} outline>Create Questions
                                                </Button>
                                                </Link>
                                            </div> */}
                                        </div><br/>
                                       
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />
                                    </div>
                                </CardBody>

                            </Card>
                       
                )}
                {showComponent && (
                    < >
                        <Roles />
                    </>
                )}

                {/*
                {showModal && (
                    < >
                        <Answers />
                    </>
                )}

                {showModals && (
                    < >
                        <QuestionCreate />
                    </>
                )} */}
            </div>
        )
    }
}