import React from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Services } from './services';
// import { Questions } from './services';
import $ from 'jquery'
import jQuery from 'jquery';
import { foundation } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import Alert from '../../shared/components/Alert';

window.$ = window.jQuery = require('jquery')


class CreateService extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            event_name: '',
            event_description: '',
            start_time: '',
            end_time: '',
            entity_type: '4',
            isLoading: false,
            showKeyWord: true,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',
            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            type: [{
                name: 'ON_DEMAND'
            }, {
                name: 'SUBSCRIPTION'
            }],


            avenue: '',
            assigned_to: '',
            selectedCounty: 'Select County/State Name',
        }
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value },
            function () {
                if (this.state.selectedType == "ON_DEMAND") {
                    this.setState({
                        showKeyWord: false,
                        opt_in_keyword: "NULL",
                        opt_out_keyword: "NULL"
                    })
                } else {
                    this.setState({
                        showKeyWord: true
                    })
                }

            }

        );


    };

    onSubmit(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 1000)

        let formData = {

            "offer_name": this.state.offer_name,
            "offer_code": this.state.offer_code,
            "shortcode_id": window.localStorage.getItem("shortcode_id"),
            "autoresponses_message": this.state.selectedMessage,
            "opt_in_keyword": this.state.opt_in_keyword,
            "opt_out_keyword": this.state.opt_out_keyword,
            "account_id": this.state.account_id,
            "service_type": this.state.selectedType
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + 'services', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then((response) => {
            console.log('bayoo', response.data)

            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                setTimeout(() => {
                    this._onButtonClick()
                    this.setState({
                        offer_name: "", showError: false, isShowError: false, offer_code: "", opt_in_keyword: "", opt_out_keyword: ""
                        , autoresponses_message: "",
                    })
                }, 2000);

            } else {
                this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                setTimeout(() => {
                    this._onButtonClick()
                    this.setState({
                        offer_name: "", showError: false, isShowError: false, offer_code: "", opt_in_keyword: "", opt_out_keyword: ""
                        , autoresponses_message: "",
                    })
                }, 2000);

            }
        })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };

    handleChangeEntityChange = (event) => {
        this.setState({ values: event.target.value });
    };

    handleChangeEntityType = (event) => {
        this.setState({ entity_type: event.target.value });
    };
    handleChangeApplicable = (event) => {
        this.setState({
            applicable_to: event.target.value,
        });
        if (event.target.value === 'entity') {

            this.setState({
                hideEntitiy: true,
                hideBranch: false,
            });
        } else if (event.target.value === 'branch') {
            this.setState({
                hideBranch: true,
                hideEntitiy: false,
            });

        }
        else if (event.target.value === 'class') {
            this.setState({
                hideBranch: false,
                hideEntitiy: false,
            });

        }
        else if (event.target.value === 'stream') {
            this.setState({
                hideBranch: false,
                hideEntitiy: false,
            });

        }
    };
    handleChangeTransit = (event) => {
        this.setState({ transit: event.target.value });
    };
    handleChangeFee = (event) => {
        this.setState({ fee: event.target.value });
    };
    handleChangePlans = (event) => {
        this.setState({ plans: event.target.value });
    };
    handleChangeScope = (event) => {
        this.setState({ event_scope: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCountry = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    handleChangeCounty = (event) => {
        this.setState({ selectedCounty: event.target.value });
    };

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }

    handleChangeMessage = (event) => {
        this.setState({ selectedMessage: event.target.value }, function () {

        });
        //alert(event.target.value)
    };


    async componentDidMount() {
        $('#message').countSms('#sms-counter');

        const [countyResponse] = await Promise.all([
            // axios.get(baseURL + 'users/1', { headers: { "Authorization": `Bearer ${window.user.access_token.value}` } }),
            axios.get(baseURL + 'accounts', { headers: { "Authorization": `Bearer ` + TOKEN } }),
        ]);
        this.setState({
            isLoading: false,
            county: countyResponse.data.data,
        },
            function () {
                console.log("admin", countyResponse.data);
            });
        //  console.log("Base", countyResponse.data );

    }
    Accounts() {
        return (this.state.county && (this.state.county.length > 0 || this.state.county.length === 0) &&
            this.state.county.map((countyItem, i) =>
                ({ label: countyItem.account_name, value: countyItem.account_id })))

    }
    onSelectChanges = value => {
        this.setState({ account_id: value.value.toString() });
    };

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { hideEntitiy } = this.state;
        const { hideBranch } = this.state;
        const { t } = this.props;

        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Services /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                  
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4><b>Create a Service</b></h4>
                                        </div>
                                        <div className="col-md-6">
                                            <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                                Services
                                            </Button>

                                        </div>
                                    </div>

                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="form" onSubmit={this.onSubmit}>
                                        <br></br>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Select Account</label>
                                                <Select
                                                    options={this.Accounts()}
                                                    onChange={this.onSelectChanges}
                                                    placeholder="Select Account"
                                                    tabSelectsValue={false}
                                                    value={this.state.onSelectChanges}
                                                    className='drop'
                                                />
                                                <br />
                                            </div>
                                        </div> */}

                                        <div className="col-md-10 offset-1">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">

                                                        <label className="form-label">Offer Code</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control input-md"
                                                            name="offer_code" required placeholder="Enter Offer Code"
                                                            value={this.state.offer_code} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-10 offset-1">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">

                                                        <label className="form-label">Offer Name</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control input-md"
                                                            name="offer_name" required placeholder="Enter Offer Name"
                                                            value={this.state.offer_name} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-10 offset-1">
                                            <Form.Group className="Focus-line" >
                                                <div className="row">
                                                    <div className="col-md-2">

                                                        <Form.Label>Service Type</Form.Label>
                                                    </div>
                                                    <div className="col-md-10">

                                                        <Form.Control
                                                            as="select"
                                                            className="form-control input-md"
                                                            placeholder="Select Type"
                                                            value={this.state.selectedType}
                                                            onChange={this.handleChangeType}
                                                            id="county_name"
                                                            option={this.selectedType}>
                                                            <option>Select Service Type</option>
                                                            {
                                                                this.state.type && this.state.type.length > 0 &&
                                                                this.state.type.map((regionItem, i) =>
                                                                    <option key={i}
                                                                        value={regionItem.name}>{regionItem.name}</option>)
                                                            }
                                                        </Form.Control>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Auto Response Message</label>
                                                <input id="input" type="text" className="form-control input-md"
                                                    name="autoresponses_message" required placeholder="Auto Response Message"
                                                    value={this.state.autoresponses_message} onChange={this.handleChange} />
                                            </div>
                                        </div> */}

                                        {this.state.showKeyWord ?
                                            <>
                                                <div className="col-md-10 offset-1">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-2">

                                                                <label className="form-label">Opt In KeyWord</label>
                                                            </div>
                                                            <div className="col-md-10">

                                                                <input id="input" type="text" className="form-control input-md"
                                                                    name="opt_in_keyword" required placeholder="Opt In KeyWord"
                                                                    value={this.state.opt_in_keyword} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-10 offset-1">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-2">

                                                                <label className="form-label">Opt Out KeyWord</label>
                                                            </div>
                                                            <div className="col-md-10">

                                                                <input id="input" type="text" className="form-control input-md"
                                                                    name="opt_out_keyword" required placeholder="Opt Out KeyWord"
                                                                    value={this.state.opt_out_keyword} onChange={this.handleChange} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </>
                                            : null}
                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">

                                                        <label className="form-label">AutoResponse Message</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <textarea name="message"

                                                            maxLength="160"

                                                            required style={{
                                                                paddingTop: '20px',
                                                                borderRadius: "5px", borderColor: "hsl(0,0%,80%)"
                                                            }}
                                                            onChange={this.handleChangeMessage} className="col-md-12"
                                                            placeholder="Hint: Enter AutoResponse Message here"
                                                            id="message" cols="col-md-1" rows="20"></textarea>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <ul id="sms-counter">
                                                            <li>Length: <span class="length"></span></li>
                                                            {/* <li>SMS: <span class="messages"></span></li> */}
                                                            <li>Per Message: <span class="remaining"></span>/<span class="per_message"></span></li>
                                                            {/* <li>Remaining: </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>

                                        <div className="col-md-10 offset-1">
                                            <br />
                                            <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Submit"}

                                            </Button> &nbsp;&nbsp;&nbsp;
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                       
                )}
            </div>
        )
    }
}
export default withTranslation('common')(CreateService);
