import React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { BreakingNews } from './broadcast';
import $ from 'jquery'
import { Link, Redirect } from 'react-router-dom';
import Alert from '../../shared/components/Alert';
window.$ = window.jQuery = require('jquery')

class CreateBreakingNews extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            event_name: '',
            event_description: '',
            start_time: '',
            end_time: '',
            entity_type: '4',
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',
            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }]
        }
    }

    async componentDidMount() {
        $('#message').countSms('#sms-counter');
        const [
            countyResponse] = await Promise.all([
                axios.get(baseURL + 'shortcodes', { headers: { "Authorization": `Bearer ` + TOKEN } }),
            ]);
        console.log(countyResponse)
        this.setState({
            isLoading: false,
            BreakingNews: countyResponse.data.data,

        })
    }
    handleChangeMessage = (event) => {
        this.setState({ selectedMessage: event.target.value }, function () {

        });
        //alert(event.target.value)
    };


    ShortCodes() {
        return (this.state.BreakingNews && this.state.BreakingNews.length > 0 &&
            this.state.BreakingNews.map((countyItem, i) =>
                ({ label: countyItem.shortcode, value: countyItem.shortcode_id })))
    }

    onSelectChanges = value => {
        this.setState({ shortcode_id: value.value.toString() },
            function () {
                fetch(baseURL + 'services?shortcode_id=' + value.value.toString() + "",
                    { headers: { "Authorization": `Bearer ` + TOKEN } })
                    .then(response => response.json())
                    .then(
                        res => {
                            this.setState({ services: res.data },
                                console.log("bayd", res)

                            )

                        }
                    )

            });
    };

    services() {
        return (this.state.services && (this.state.services.length > 0 || this.state.services.length === 0) &&
            this.state.services.map((countyItem, i) => {
                return countyItem.service_type === 'SUBSCRIPTION' ? ({ label: countyItem.offer_name, value: countyItem.service_id }) : {}
            }
            ))

    }

    onSelectService = value => {
        this.setState({ service_id: value.value.toString() })
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    onSubmit(e) {
        e.preventDefault();

        let formData =
        {
            "shortcode_id": this.state.shortcode_id,
            "service_id": this.state.service_id,
            "message": this.state.selectedMessage
        }



        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + 'breakingNews', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    // window.setTimeout(function () {
                    //     window.location.reload();
                    // }, 2000);
                    window.setTimeout(() => {
                        this._onButtonClick();
                        this.setState({ isShowError: false })
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    window.setTimeout(() => {
                        this._onButtonClick();
                        this.setState({ isShowError: false })
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };

    handleChangeEntityChange = (event) => {
        this.setState({ values: event.target.value });
    };

    handleChangeEntityType = (event) => {
        this.setState({ entity_type: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCountry = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    handleChangeCounty = (event) => {
        this.setState({ selectedCounty: event.target.value });
    };

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                           
                            <Redirect to="breakingNews" /> : null
                        }
                    </div>
                )}

                {!hideComponent && (

                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="bold-text">Create Breaking News</h5>
                                </div>
                                <div className="col-md-6">
                                    <Link to="breakingNews">
                                        <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                            Breaking News
                                            </Button>
                                    </Link>

                                </div>
                            </div>

                            {this.state.showError ? <div >

                                <Alert color="danger" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>


                            </div> : null}<br></br>

                            {this.state.isShowError ? (
                                <div>

                                    <Alert color="success" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>

                                </div>

                            ) : null}<br></br>
                            <form className="form" onSubmit={this.onSubmit}>
                                <br /><br />
                                <div className="col-md-10  offset-1">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Select ShortCodes</label>
                                            </div>
                                            <div className="col-md-10">
                                                <Select
                                                    options={this.ShortCodes()}
                                                    onChange={this.onSelectChanges}
                                                    placeholder="Select ShortCodes"
                                                    tabSelectsValue={false}
                                                    value={this.state.onSelectChanges}
                                                    className='drop'
                                                />
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                                <div className="col-md-10  offset-1">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Select Services</label>
                                            </div>
                                            <div className="col-md-10">
                                                <Select
                                                    options={this.services()}
                                                    onChange={this.onSelectService}
                                                    placeholder="Select Services"
                                                    tabSelectsValue={false}
                                                    value={this.state.onSelectService}
                                                    className='drop'
                                                />
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-10  offset-1">
                                    <br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">

                                                <label className="form-label">Breaking News</label>
                                            </div>
                                            <div className="col-md-8">
                                                <textarea name="message" maxLength="160" required style={{
                                                    paddingTop: '20px',
                                                    borderRadius: "5px", borderColor: "hsl(0,0%,80%)"
                                                }}
                                                    onChange={this.handleChangeMessage} className="col-md-12"
                                                    placeholder="Hint: Enter Breaking News here"
                                                    id="message" cols="col-md-1" rows="20"></textarea>
                                            </div>
                                            <div className="col-md-2">
                                                <ul id="sms-counter">
                                                    <li>Length: <span class="length"></span></li>
                                                    {/* <li>SMS: <span class="messages"></span></li> */}
                                                    <li>Per Message: <span class="remaining"></span>/<span class="per_message"></span></li>
                                                    {/* <li>Remaining: </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                                <div className="col-md-10  offset-1">
                                    <br />
                                    <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                        {this.state.isLoading ? "Please Wait..." : "Submit"}

                                    </Button> &nbsp;&nbsp;&nbsp;
                                        </div>

                            </form>
                        </CardBody>
                    </Card>
                )}
            </div>
        )
    }
}
export default withTranslation('common')(CreateBreakingNews);
