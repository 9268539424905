import React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import { Users } from '../users/Users';
import LogIn from '../Account/LogIn';
import Alert from '../../shared/components/Alert';
import { UserDetails } from '../users/userdetails';
import { Redirect } from 'react-router-dom';

window.$ = window.jQuery = require('jquery')

class UserAdd extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            event_name: '',
            event_description: '',
            start_time: '',
            end_time: '',
            entity_type: '4',
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',

            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }]
        }
    }

    async componentDidMount() {
        const [
            countyResponse, shortResponse, groupResponse] = await Promise.all([
                axios.get(baseURL + 'accounts', { headers: { "Authorization": `Bearer ` + TOKEN } }),
                axios.get(baseURL + 'shortcodes', { headers: { "Authorization": `Bearer ` + TOKEN } }),
                axios.get(baseURL + 'groups', { headers: { "Authorization": `Bearer ` + TOKEN } }),
            ]);
        console.log(groupResponse)
        this.setState({
            isLoading: false,
            BreakingNews: countyResponse.data.data,
            shortResponse: shortResponse.data.data,
            groupResponse: groupResponse.data.data
        })
    }
    handleChangeMessage = (event) => {
        this.setState({ selectedMessage: event.target.value }, function () {

        });
        //alert(event.target.value)
    };


    ShortCodes() {
        return (this.state.BreakingNews && this.state.BreakingNews.length > 0 &&
            this.state.BreakingNews.map((countyItem, i) =>
                ({ label: countyItem.account_name, value: countyItem.account_id })))

    }

    ShortCodes2() {
        return (this.state.shortResponse && this.state.shortResponse.length > 0 &&
            this.state.shortResponse.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.id })))

    }

    onSelectChangesShort = value => {
        this.setState({ shortcode_id: value.value.toString() });
    };

    onSelectChanges = value => {
        this.setState({ account_id: value.value.toString() });
    };

    services() {
        return (this.state.groupResponse && (this.state.groupResponse.length > 0 || this.state.groupResponse.length === 0) &&
            this.state.groupResponse.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.id })))
    }

    onSelectService = value => {
        this.setState({ role_id: value.value.toString() })
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    onSubmit(e) {
        e.preventDefault();


        if (this.state.password === this.state.confirm_password) {
            let formData =
            {
                "password": this.state.password,
                "email": this.props.location.state.email
            }





            console.log("DATA", JSON.stringify(formData))
            this.setState({ isLoading: true });
            axios.post(baseURL + 'change-password', formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.props.match.params.token
                },
            })
                .then((response) => {
                    console.log('bayoo', response.data)

                    if (response.data.status) {
                        this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                        // window.setTimeout(function () {
                        //     window.location.reload();
                        // }, 2000);
                        window.setTimeout(() => {
                            this._onButtonClick();
                            this.setState({ isShowError: false })
                        }, 2000);
                    } else {
                        this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                        window.setTimeout(() => {
                            this.setState({ isShowError: false })
                        }, 2000);
                    }
                })
                .catch((error) => {
                    console.log('bayoo', error.response.data)
                    this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
                })
        } else {
            this.setState({
                isLoading: false,
                error: "Password do not match",
                errorShow: true,
                submitted: true,
                alert_error_color: "alert alert-danger"
            });


        }
    }


    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };

    handleChangeEntityChange = (event) => {
        this.setState({ values: event.target.value });
    };

    handleChangeEntityType = (event) => {
        this.setState({ entity_type: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCountry = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    handleChangeCounty = (event) => {
        this.setState({ selectedCounty: event.target.value });
    };

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true

        });
    }
    changeStatus() {
        this.setState({
            isShowError: false
        });
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;


        return (
            <div>
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Redirect to="/users" />
                            :
                            null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <>
                        <br /><br /><br></br>

                        <Col className="col-md-8 offset-2">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4 className="bold-text">Change Password</h4>
                                        </div>

                                        <div className="col-md-6">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                Cancel
                                            </Button>

                                        </div>
                                    </div>

                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="form" onSubmit={this.onSubmit}>
                                        <br /><br />
                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Password</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="password" className="form-control" name="password"
                                                            required placeholder="Password"
                                                            value={this.state.password}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Confirms Password</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="password" className="form-control" name="confirm_password"
                                                            required placeholder="Confirm Password"
                                                            value={this.state.confirm_password}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-8 offset-2">
                                            <br />
                                            <div class="text-right">
                                                <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-righ" >
                                                    {this.state.isLoading ? "Please Wait..." : "Submit"}

                                                </Button> &nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )
                }
            </div >
        )
    }
}
export default withTranslation('common')(UserAdd);
