import React from 'react';
import { baseURL } from '../../../_helpers';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      email_address: "",
      password: "",
      showPassword: false,
      setShowPassword: false,
      channel_type: "portal",
      error: "",
      errorMsg: "",
      role: "",
      id: "",
      user: [],
      statusMessage: "",
      isShowSuccess: false,
      submitted: false,
      alert_error_color: "",
      isLoggedIn: false,
      isChecked: false,
      errorShow: false,
      successShow: false,
      statis: []
    };

  }

  handleChangeEmail = event => {
    this.setState({ email_address: event.target.value });
  };
  handleChangePassword = event => {
    this.setState({ password: event.target.value });
  };


  showPasswordToggle = () => {
    // eslint-disable-next-line
    if (this.state.showPassword == true) {
      this.setState({
        showPassword: false,
      })
    } else {
      this.setState({
        showPassword: true,
      })
    }
  };

  onSubmit = e => {

    e.preventDefault();
    this.setState({ isLoading: true });
    // eslint-disable-next-line
    if (this.state.email_address == "") {
      this.setState({
        isLoading: false,
        error: "Email is required",
        errorShow: true,
        submitted: true,

        alert_error_color: "alert alert-danger"
      });
    } else {

      let formData = {
        "email": this.state.email_address,
      }
      console.log("DATA", formData)
      this.setState({ isLoading: true });

      axios.post(baseURL + 'reset-password-request', formData
      ).then((response) => {
        console.log("token", response.data.success);
        // eslint-disable-next-line
        this.setState({
          statusMessage: response.data.status_message,
          isShowError: true,
          errorShow: false,
          submitted: true,
          email_address: "",
          isLoading: false,
          isLoggedIn: true
        }, function () {
          window.setTimeout(() => {
            this.setState({ isShowError: false })
          }, 2000);
        });

      }).catch(error => {
        console.log('bayoo', error.response)
        this.setState({
          alert_error_color: "alert alert-danger",
          errorShow: true,
          error: "Error",
          submitted: true,
          isLoading: false
        });
        this.setState({ password: "", email_address: "" });
      })


    }
  };




  componentDidMount() {
    this.setState({ isChecked: true });
    axios.get('http://www.geoplugin.net/json.gp').then(res => {
      this.setState({ statusMessage: res.data.status_message, isShowError: false, isLoading: false });
      this.setState({
        students: res.data,
        isLoading: false,
      },
        function () {
          console.log("data", res.data);
        });
    });
  }

  render() {
    const { submitted } = this.state;
    const { errorShow } = this.state;

    return (
      <div className="account account">

        <div className="account__wrapper">

          <div className="account__card" style={{ paddingTop: "30px", paddingBottom: "30px" }} >
            <div className="account__head">
              <h3 className="account__title">Welcome to
              <span className="account__logo"> Media
                <span className="account__logo-accent">Max</span>
                </span>
              </h3>
              <h4 className="account__subhead subhead" style={{ color: "black" }}>Forgot Password</h4>
            </div>

            <Form className="form login-form" onSubmit={this.onSubmit}>
              {submitted ? (
                <>
                  {this.state.isShowError ? (
                    <div
                      color="success"
                      style={{ fontSize: "13px", color: "green" }}>
                      {this.state.statusMessage}
                    </div>

                  ) : null}
                  {errorShow && (

                    <div>
                      <div
                        color="red"
                        style={{ fontSize: "13px", color: "red" }}>
                        {this.state.error}
                      </div>

                    </div>
                  )}

                </>
              ) : null}
              <div className="form__form-group">
                <br></br>
                <span className="form__form-group-label" style={{ color: "white" }}>Email</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AlternateEmailIcon />
                  </div>
                  <Form.Control
                    autoFocus
                    type="text"
                    name="email_address"
                    style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                    placeholder="Email Address"
                    className="input-without-border-radius"
                    value={this.state.email_address}
                    onChange={this.handleChangeEmail}
                  />

                </div>
              </div>


              <div className="form__form-group">
                <br /><br />
                {
                  <Button className="account__btn" type='submit' style={{ color: "" }} outline color="primary"> {
                    this.state.isLoading ? "Please wait..." : "Submit"
                  }</Button>

                }
              </div>
              <div className="account__have-account">
                <p>Remember your password?  <NavLink to="/log_in">Login</NavLink></p>
              </div>
            </Form>

          </div>
        </div>
      </div >
    );
  }
}

export default ForgotPassword;
