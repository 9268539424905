import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col, Form } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import createSchedule from './createSchedule';
// import createBN from './createBN';

export class Schedulers extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this.columns = [


            {
                key: "message",
                TrOnlyClassName: 'tsc',
                text: "Message",
                className: "tsc",
                align: "left"
            },

            {

                key: "count_sent",
                TrOnlyClassName: 'tsc',
                text: "Count Sent",
                className: "tsc",
                align: "left"
            },


            {
                key: "processed",
                TrOnlyClassName: 'tsc',
                text: "Processed",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment >
                            {record.processed === 'YES' ?
                                < span class="badge-success" style={{ fontSize: "14px", borderRadius: "5px", color: "white", padding: "2px" }}>
                                    {record.processed}
                                </ span>
                                : null}

                            {record.processed === 'NO' ?
                                < span class="badge-danger" style={{ fontSize: "14px", borderRadius: "5px", color: "white", padding: "2px" }}>
                                    {record.processed}
                                </ span>
                                : null}

                        </Fragment >
                    );
                }

            },
            {
                key: "dates",
                TrOnlyClassName: 'cell',
                text: "Date",
                className: "cell",
                align: "left"
            }
            // {
            //     key: "action",
            //     text: "Options",
            //     TrOnlyClassName: 'cell',
            //     className: "cell",
            //     width: 250,
            //     sortable: false,
            //     cell: record => {
            //         return (
            //             <Fragment className="center" >

            //                 <button className=" btn-danger"
            //                     title="Delete details"
            //                     style={
            //                         { marginRight: '10px' }}
            //                     onClick={
            //                         () => { if (window.confirm('Are you sure you want to remove this record?')) this.onDelete(record) }} >
            //                     <span className="fa fa-trash dt-icon-btn" > Remove </span>
            //                 </button>
            //                 <button className=" btn-success"
            //                     title="Approve"
            //                     style={
            //                         { marginRight: '10px' }}
            //                     onClick={
            //                         () => { if (window.confirm('Are you sure you want to approve this record?')) this.onApprove(record) }} >
            //                     <span className="fa fa- dt-icon-btn" >Approve</span>
            //                 </button>

            //                 {/* <button className=" btn-danger"
            //                     title="Reject"
            //                     style={
            //                         { marginRight: '10px' }}
            //                     onClick={
            //                         () => { this.onAnswers(record) }} >
            //                     <span className="fa fa-trash dt-icon-btn" > Reject </span>
            //                 </button> */}



            //             </Fragment>
            //         );
            //     }
            // }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            showModals: false,
            showEdit: false,
            hideComponent: false,
            data: [],
        };
    }


    onApprove(record) {
        // console.log("Delete Record", record);
        let formData = {
            "approved": "1"
        }
        axios.put(baseURL + "schedules/" + record.id + "/approve", formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }

    onDelete(record) {

        console.log("Delete Record", record);
        axios.delete(baseURL + "schedules/" + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }
    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        let url = baseURL + "schedules?" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)

                    if (this.state.admins.length > 0 || this.state.admins.length === 0) {

                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: branchResponse.data.total
                            })
                            let mail;
                            let mbalance;
                            let phone;
                            let index = { idx: i + 1 }
                            if (this.state.admins[i].email == null) {
                                mail = { mail: "_" };
                            } else {
                                mail = { mail: this.state.admins[i].email };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };


                            }

                            let datesample = this.state.admins[i].schedule_date + " " + this.state.admins[i].schedule_time

                            
                            let date = { dates: (moment(datesample).format('YYYY-MM-DD hh:mm:ss')) };
                            data.push(Object.assign(date, mail, phone, index, mbalance, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);

                        }
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
        });
    }

    _onButtonClick2() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
            showModals: false
        });
    }

    onEdit(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showEdit: true,
        }, function () {
            // localStorage.setItem("question_id", this.state.survey_id)
        });

    }

    onAnswers(record) {
        localStorage.setItem("question_id", this.state.survey_id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: true,
        }, function () {
        });

    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal } = this.state;
        const { showModals } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    < Card >
                        <CardBody >
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>List of Schedulers</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        {/* <Link to="createSchedule">
                                                    <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick2} outline>Create Schedulers
                                                </Button>
                                                </Link> */}
                                    </div>
                                </div>
                                <br />
                                {this.state.showError ? <div style={{ color: 'red' }}>
                                    {this.state.statusMessage}
                                </div> : null}
                                {this.state.isShowError ? (
                                    <div
                                        color="success"
                                        style={{ fontSize: "13px", color: "green" }}>
                                        {this.state.statusMessage}
                                    </div>

                                ) : null}
                                <br></br>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    total_record={this.state.total}
                                    onChange={this.tableChangeHandler} />
                            </div>
                        </CardBody>

                    </Card>
                )}
                {showComponent && (
                    < >
                        <createSchedule />
                    </>
                )}
                {/* 
                {showModal && (
                    < >
                        <Answers />
                    </>
                )}

                {showModals && (
                    < >
                        <QuestionCreate />
                    </>
                )} */}

            </div>
        )
    }
}